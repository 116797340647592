import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"

import styled from "styled-components"

export default function HeaderMenu() {
  return (
    <Wrapper>
      <Menu>
        <MenuItem>Купить прокси</MenuItem>
        <MenuItem>Наши прокси</MenuItem>
        <MenuItem>Наши прокси</MenuItem>
        <MenuItem>Озывы</MenuItem>
        <MenuItem>FAQ</MenuItem>
        <MenuItem>Контакты</MenuItem>
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
const Menu = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  padding: 0 15px;
  box-sizing: border-box;
`
const MenuItem = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  
  :hover{
    cursor: pointer;
  }
`

