import React, { useState, useEffect, useRef, useCallback, useReducer } from "react"
import styled from "styled-components"

export default function Button({
  preset = null, placeholder, align = 'center', color = '#fff'
                               }) {
  return (
    <Wrapper align={align} color={color}>
      {placeholder}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  width: fit-content;
  padding: 10px 25px;
  font-weight: bold;
  background-color: #F07867;
  border-radius: 40px;
  text-align: center;
  box-sizing: border-box;
  color: ${props => props.color};
  
  :hover{
    cursor: pointer;
  }
`
