import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components";
import HeaderMenu from "./HeaderMenu"
import Button from "../elements/button"

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <HeaderItem></HeaderItem>
    <HeaderItem>
      <HeaderMenu/>
    </HeaderItem>
    <HeaderItem>
      <Button placeholder={'Вход и Регистрация'}/>
    </HeaderItem>

  </HeaderWrapper>
)

const HeaderWrapper = styled.header`
 display: flex;
 width: 100%;
 padding-top: 30px;
`

const HeaderItem= styled.div`
 flex: 1;
 
 :first-child {
  flex: inherit;
  width: 150px
 }
 
 :last-child {
   flex: inherit;
   width: 250px;
   display: flex;
   
 }
 
 
`


export default Header
